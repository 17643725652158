<template>
    <div class="cloud-storage">
        <div class="cloud-storage__header">
            <h3 class="cloud-storage__title">Объем облачного хранилища</h3>
        </div>
        <div class="cloud-storage__wrap">
            <cloud-storage-item :data="storage_data"/>
            <br/>
            <vue-apex-charts
                class='cloud-storage__vue-apex-charts'
                type="donut"
                :options='options'
                :series="series"
            ></vue-apex-charts>

        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'CloudStorage',
        components: {
            CloudStorageItem: () => import('./CloudStorageItem'),
            VueApexCharts
        },
        data() {
            return {
                storage_data: {
                    used: 0,
                    used_percent: 0,
                    total: 0
                },
                charts_data: {},
                series: [33, 33, 33, 33],
                options: {
                    plotOptions: {
                        pie: {
                            donut: {
                                size: '60%',
                                labels: {
                                    show: false,
                                    total: {
                                        show: true,
                                        showAlways: false,
                                        label: 'Всего',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        color: '#222'
                                    }
                                }
                            }
                        }
                    },
                    chart: {
                        type: 'donut'
                    },
                    labels: ['Документы', 'Пользователи', 'Прочее', 'Свободно'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            plotOptions: {
                                pie: {
                                    donut: {
                                        size: '50%'
                                    }
                                }
                            }
                        }
                    }]
                }
            };
        },
        methods: {
            onResize() {
                if (document.documentElement.clientWidth < 480) {
                    this.options = { ...this.options, legend: { position: 'bottom' } };
                } else {
                    this.options = { ...this.options, legend: { position: 'right' } };
                }
            }
        },
        created() {
            session
                .get('/api/v1/rate/cloud_storage_capacity/')
                .then(response => {
                    const
                        data = response.data;
                        let used_buy_size = 0; // Сколько занимает доп. место %
                        let used_free_size = 0; // Сколько занимает доп. место %,
                        const add_size = data.use_size - data.init_size; // сколько занимает доп места в байтах

                    if (add_size > 0) {
                        const total_size = data.add_size * data.init_size + data.init_size; // Всего доступно места в байтах
                        used_free_size = data.init_size * 100 / total_size; // Сколько занимает место %
                        used_buy_size = add_size * 100 / total_size; // Сколько занимает доп. место %
                        this.storage_data.total = 1 + data.add_size; // Всего выделено
                        this.storage_data.used = (data.use_size).toFixed(1); // Занято в гб
                    } else {
                        this.storage_data.total = data.init_size / 1024.0;
                        used_free_size = data.use_size * 100 / data.init_size; // левая часть %
                        this.storage_data.used = (data.use_size).toFixed(1); // Занято в гб
                    }

                    this.storage_data.used_percent = (used_free_size + used_buy_size).toFixed(1);
                    this.series = [data.documents_size, data.user_size, data.other_size, data.free_size];
                })
                .catch(() => {
                });
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    };
</script>

<style lang="sass" scoped>
    .cloud-storage
        display: flex
        flex-direction: column
        /*margin-left: 100px*/
        &__vue-apex-charts
            max-width: 600px
            width: 100%

        &__wrap
            margin-top: 30px

        &__title
            margin-bottom: 0

        &__footer
            display: flex
            flex-direction: column

        &__quota
            color: #A4AEBD
            font-size: 0.875rem

        &__footer
            margin-top: 30px

    @media (max-width: 782px)
        .cloud-storage
            width: 100%
</style>
